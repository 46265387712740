import { Modal, Space } from 'antd';
import { useState } from 'react';
import { t } from '@gowgates/utils';
import { useFormBuilder } from '../../../hooks/useFormBuilder';
import { useValidationsQuery } from '../../../hooks/validations/useValidationsQuery';
import { EmptyValidations } from './Empty';
import { ValidationsList } from './List';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

export const ValidationsModal = ({ isOpen, closeModal }: Props) => {
  const { fieldableId, fieldableType } = useFormBuilder();
  const [adding, setAdding] = useState(false);
  const { validations } = useValidationsQuery({
    validatableId: fieldableId,
    validatableType: fieldableType,
    enabled: isOpen
  });

  return (
    <Modal
      title={t('activerecord.models.validation.other')}
      open={isOpen}
      onCancel={closeModal}
      width={800}
      footer={null}
    >
      <Space direction="vertical" className="w-100">
        {validations?.length === 0 ? (
          <EmptyValidations adding={adding} setAdding={setAdding} />
        ) : (
          <ValidationsList adding={adding} setAdding={setAdding} validations={validations} />
        )}
      </Space>
    </Modal>
  );
};
