import { useQuery } from '@tanstack/react-query';
import { t } from '@gowgates/utils';
import { usePageTitle, useTable } from '@gowgates/core';
import usePermissions from '../../hooks/usePermissions';
import Page from '../../components/Page';
import { getClaims } from '../../api/endpoints';
import useStructures from '../../hooks/useStructures';
import { TablePagination } from '../../components/TablePagination';
import { ClaimInTable } from '../../types';
import { ClaimsTable } from './Table';
import { TableHeader } from './TableHeader';
import { ClaimsActions } from './Actions';

const Claims = () => {
  const { permissions } = usePermissions();
  const { handleTableChange, sorter, filters, search, selectedRows, rowSelection } =
    useTable<ClaimInTable>({
      hasRowSelection: permissions.assignClaimsBulk || permissions.assignClaimsToMeBulk
    });
  usePageTitle(t('activerecord.models.claim.other'));

  const { activeStructures } = useStructures();

  const { isFetching, data: claims } = useQuery({
    queryKey: ['claims', search],
    queryFn: () => getClaims(search)
  });

  if (!activeStructures) {
    return;
  }

  return (
    <Page
      title={t('activerecord.models.claim.other')}
      actions={<ClaimsActions structures={activeStructures} />}
      overlap
    >
      <TableHeader selectedRows={selectedRows} />

      <div className="page-table">
        <ClaimsTable
          claims={claims?.rows}
          loading={isFetching}
          sorter={sorter}
          filters={filters}
          structures={activeStructures}
          rowSelection={rowSelection}
          onChange={handleTableChange}
        />

        <TablePagination pagination={claims?.pagination} modelName="claim" />
      </div>
    </Page>
  );
};

export default Claims;
