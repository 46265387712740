import { Modal, Button, Typography } from 'antd';
import { t } from '@gowgates/utils';
import { Question } from '../FieldBuilder/preview/Question';
import { Input } from '../FieldBuilder/preview/Input';
import { Description } from '../FieldBuilder/preview/Description';
import { useFormBuilder } from '../../hooks/useFormBuilder';
import { useFieldTemplatesQuery } from '../../hooks/field-templates/useFieldTemplatesQuery';

type FieldTemplateModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};

export const FieldTemplateModal = ({ isOpen, closeModal }: FieldTemplateModalProps) => {
  const { addTemplateField } = useFormBuilder();
  const { fields } = useFormBuilder();
  const { fieldTemplates: allFieldTemplates } = useFieldTemplatesQuery();

  const fieldNames = fields.map((field) => field.name);

  const fieldTemplates = allFieldTemplates.filter((field) => fieldNames.indexOf(field.name) < 0);

  return (
    <Modal
      title="Add field from template"
      open={isOpen}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          {t('globals.cancel')}
        </Button>
      ]}
    >
      <div className="form-builder">
        {fieldTemplates.map((fieldTemplate) => (
          <div
            className="field-container field-container-preview"
            role="button"
            onClick={() => addTemplateField(fieldTemplate)}
            onKeyUp={() => addTemplateField(fieldTemplate)}
            tabIndex={-1}
            key={fieldTemplate.name}
          >
            <Question field={fieldTemplate} />

            <Input field={fieldTemplate} />

            <Description field={fieldTemplate} />
          </div>
        ))}

        {fieldTemplates.length === 0 && (
          <Typography.Text type="secondary" italic>
            {t('dynamicData.field.noTemplates')}
          </Typography.Text>
        )}
      </div>
    </Modal>
  );
};
