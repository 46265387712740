import React from 'react';
import { Link } from 'react-router-dom';
import { TaskInTable } from '../../types';

type Props = {
  task: TaskInTable;
  children: React.ReactNode;
};

export const TaskCol = ({ task, children }: Props) => {
  if (task.permissions.showClaim) {
    let claimLocation = '';

    if (task.claimStatus !== 'draft') {
      if (task.itemId) {
        claimLocation = `/items?id=${task.itemId}`;
      } else if (task.claimTab === 'tasks') {
        claimLocation = `/${task.claimTab}?id=${task.id}`;
      } else {
        claimLocation = `/${task.claimTab}`;
      }
    }

    return (
      <Link to={`/claims/${task.claimId}${claimLocation}`} className="col-clickable">
        {children}
      </Link>
    );
  }

  return children;
};
