import { Flex, Pagination, Typography } from 'antd';
import { useLocation, useNavigate } from 'react-router';
import qs from 'query-string';
import { t } from '@gowgates/utils';

type PaginationType = {
  firstRecord: number;
  lastRecord: number;
  total: number;
  pageSize: number;
  current: number;
};

type TablePaginationProps = {
  pagination?: PaginationType;
  modelName: string;
};

export const TablePagination = ({ pagination, modelName }: TablePaginationProps) => {
  const search = qs.parse(useLocation().search);
  const navigate = useNavigate();

  const handlePageChange = (page: number) => {
    const newParams = { ...search };

    if (page !== 1) {
      newParams.page = `${page}`;
    } else {
      delete newParams['page'];
    }

    navigate({ search: qs.stringify(newParams) });
  };

  if (!pagination) {
    return null;
  }

  return (
    <Flex align="center" justify="space-between">
      <Typography.Text>
        {pagination &&
          t('globals.showingRecords', {
            firstRecord: pagination.firstRecord,
            lastRecord: pagination.lastRecord,
            total: pagination.total,
            modelName: t(`activerecord.models.${modelName}`, { count: pagination.total || 0 })
          })}
      </Typography.Text>
      <Pagination
        total={pagination.total}
        pageSize={pagination.pageSize}
        current={pagination.current}
        onChange={handlePageChange}
        showSizeChanger={false}
      />
    </Flex>
  );
};
