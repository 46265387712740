import { camelCase } from 'change-case';
import { Tooltip } from 'antd';
import { AnyObject, formatBoolean } from '@gowgates/utils';
import { ArrayFormatter } from '../ArrayFormatter';
import { ChoiceFromListFormatter } from '../ChoiceFromListFormatter';
import {
  isArrayField,
  isBooleanField,
  isChoiceField,
  isChoiceFieldWithList,
  isDateField,
  isEmailField,
  isFileField,
  isMoneyField,
  isMultiChoiceField,
  isPercentField,
  isPhoneField,
  isTextField,
  isUrlField
} from '../../utils';
import { ArrayNode, Field, FileType } from '../../types';
import { DateFormatter } from '../DateFormatter';
import { MoneyFormatter } from '../MoneyFormatter';
import { File } from '../../components';

type DynamicFormatterProps = {
  data: AnyObject;
  field: Field;
  TooltipComponent?: typeof Tooltip;
};

export const DynamicFormatter = ({ data, field, TooltipComponent }: DynamicFormatterProps) => {
  const fieldValue = data?.[camelCase(field.name)];

  if (fieldValue === null || fieldValue === undefined || fieldValue === '') {
    return '-';
  }

  if (isMoneyField(field)) {
    const currencyValue = data[`${camelCase(field.name)}Currency`];
    const rateValue = data[`${camelCase(field.name)}Rate`];
    return (
      <MoneyFormatter
        value={fieldValue as string}
        currency={currencyValue as string}
        rate={rateValue as number}
        TooltipComponent={TooltipComponent}
      />
    );
  }

  if (isDateField(field)) {
    return (
      <DateFormatter
        value={fieldValue as string}
        format={field.level}
        TooltipComponent={TooltipComponent}
      />
    );
  }

  if (isChoiceFieldWithList(field)) {
    return (
      <ChoiceFromListFormatter
        field={field}
        fieldValue={fieldValue as number[]}
        TooltipComponent={TooltipComponent}
      />
    );
  }

  if (isMultiChoiceField(field)) {
    const options = field.options?.filter((o) => fieldValue.indexOf(o.key) >= 0) || [];
    const text = options.length > 0 ? options?.map((option) => option.value).join(', ') : '-';

    if (TooltipComponent) {
      return <TooltipComponent title={text}>{text}</TooltipComponent>;
    }

    return text;
  }

  if (isChoiceField(field)) {
    const option = field.options?.find((o) => o.key === fieldValue);
    const text = option?.value || fieldValue;

    if (TooltipComponent) {
      return <TooltipComponent title={text}>{text}</TooltipComponent>;
    }

    return text;
  }

  if (isBooleanField(field)) {
    const text = formatBoolean(fieldValue as boolean);

    if (TooltipComponent) {
      return <TooltipComponent title={text}>{text}</TooltipComponent>;
    }

    return text;
  }

  if (isPhoneField(field)) {
    const output = data[`${camelCase(field.name)}Formatted`] as string;

    return (
      <a href={`tel:${data[`${camelCase(field.name)}Normalised`]}`}>
        {TooltipComponent ? <TooltipComponent title={output}>{output}</TooltipComponent> : output}
      </a>
    );
  }

  if (isArrayField(field)) {
    return <ArrayFormatter values={fieldValue as ArrayNode[]} field={field} />;
  }

  if (isPercentField(field)) {
    const text = `${fieldValue}%`;

    if (TooltipComponent) {
      return <TooltipComponent title={text}>{text}</TooltipComponent>;
    }

    return text;
  }

  if (isEmailField(field)) {
    return (
      <a href={`mailto:${fieldValue}`}>
        {TooltipComponent ? (
          <TooltipComponent title={fieldValue}>{fieldValue}</TooltipComponent>
        ) : (
          fieldValue
        )}
      </a>
    );
  }

  if (isUrlField(field)) {
    return (
      <a href={fieldValue} target="_blank" rel="noreferrer">
        {TooltipComponent ? (
          <TooltipComponent title={fieldValue}>{fieldValue}</TooltipComponent>
        ) : (
          fieldValue
        )}
      </a>
    );
  }

  if (isTextField(field)) {
    const output = (
      <div className="html-text">
        {fieldValue.split('\n').map((paragraph: string, index: number) => (
          <p key={paragraph || index}>{paragraph || <br />}</p>
        ))}
      </div>
    );

    if (TooltipComponent) {
      <TooltipComponent title={fieldValue}>{output}</TooltipComponent>;
    }

    return output;
  }

  if (isFileField(field)) {
    return fieldValue.map((f: FileType) => (
      <File key={f.id} url={f.file.url} type={f.file.type} name={f.file.name} />
    ));
  }

  return (
    <span>
      {TooltipComponent ? (
        <TooltipComponent title={fieldValue}>{fieldValue}</TooltipComponent>
      ) : (
        fieldValue
      )}
    </span>
  );
};
