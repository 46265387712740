import { useQuery } from '@tanstack/react-query';

import { t } from '@gowgates/utils';
import { usePageTitle, useTable } from '@gowgates/core';

import TableSearch from '../../components/TableSearch';
import usePermissions from '../../hooks/usePermissions';
import { getTasks } from '../../api/endpoints';
import Page from '../../components/Page';
import { TaskInTable } from '../../types';
import { TablePagination } from '../../components/TablePagination';
import { TasksTable } from './Table';
import { ReassignTasks } from './ReassignTasks';
import { TasksActions } from './TasksActions';

export const Tasks = () => {
  usePageTitle(t('activerecord.models.task.other'));
  const { permissions } = usePermissions();
  const { sorter, filters, handleTableChange, search, rowSelection, selectedRows } =
    useTable<TaskInTable>({
      hasRowSelection: permissions.assignTasksBulk || permissions.assignTasksToMeBulk
    });

  const { isFetching, data: tasks } = useQuery({
    queryKey: ['tasks', search],
    queryFn: () => getTasks(search)
  });

  return (
    <Page title={t('activerecord.models.task.other')} overlap actions={<TasksActions />}>
      <TableSearch />

      <ReassignTasks selectedRows={selectedRows} cacheKey={['tasks']} />

      <TasksTable
        tasks={tasks?.rows}
        loading={isFetching}
        sorter={sorter}
        filters={filters}
        rowSelection={rowSelection}
        onChange={handleTableChange}
        columnConfig="tasksColumns"
      />

      <TablePagination pagination={tasks?.pagination} modelName="task" />
    </Page>
  );
};
