import { Col, Row, Input, Select } from 'antd';
import { t } from '@gowgates/utils';
import { FormItem } from '../../forms';

export const Booleans = () => {
  const displayOptions = [
    { value: 'radio', label: t('dynamicData.field.displayTypes.radio') },
    { value: 'select_box', label: t('dynamicData.field.displayTypes.selectBox') }
  ];

  return (
    <Row gutter={30}>
      <Col span={8}>
        <FormItem name="trueLabel">
          <Input placeholder={t('dynamicData.field.blankForYes')} />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem name="falseLabel">
          <Input placeholder={t('dynamicData.field.blankForNo')} />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem name="displayType">
          <Select placeholder={displayOptions[0].value} options={displayOptions} />
        </FormItem>
      </Col>
    </Row>
  );
};
