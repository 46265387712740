import { Tooltip } from 'antd';
import { useLists } from '../../hooks';
import { Field } from '../../types';

type ChoiceFromListFormatterProps = {
  field: Field;
  fieldValue: number[];
  TooltipComponent?: typeof Tooltip;
};

export const ChoiceFromListFormatter = ({
  field,
  fieldValue,
  TooltipComponent
}: ChoiceFromListFormatterProps) => {
  const { choiceFromListValue } = useLists();

  const text = choiceFromListValue({ field, fieldValue });

  if (TooltipComponent) {
    return <TooltipComponent title={text}>{text}</TooltipComponent>;
  }

  return text;
};
