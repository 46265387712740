import { Button, Empty } from 'antd';
import { t } from '@gowgates/utils';
import { ValidationsForm } from './Form';

type Props = {
  adding: boolean;
  setAdding: (adding: boolean) => void;
};

export const EmptyValidations = ({ adding, setAdding }: Props) => {
  if (adding) {
    return <ValidationsForm setAdding={setAdding} />;
  }

  return (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('dynamicData.validation.none')}>
      <Button onClick={() => setAdding(true)} type="primary">
        {t('globals.createNow')}
      </Button>
    </Empty>
  );
};
