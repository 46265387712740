import { Button, Flex, Space } from 'antd';
import { t } from '@gowgates/utils';
import { Validation } from '../../../types';
import { ValidationDetails } from './Details';
import { ValidationsForm } from './Form';

type Props = {
  adding: boolean;
  setAdding: (adding: boolean) => void;
  validations?: Validation[];
};

export const ValidationsList = ({ adding, setAdding, validations }: Props) => (
  <Space direction="vertical" className="w-100">
    {validations?.map((validation) => (
      <ValidationDetails key={validation.id} validation={validation} />
    ))}

    {!adding && (
      <Flex justify="flex-end">
        <Button onClick={() => setAdding(true)} type="primary">
          {t('dynamicData.validation.add')}
        </Button>
      </Flex>
    )}

    {adding && <ValidationsForm setAdding={setAdding} />}
  </Space>
);
