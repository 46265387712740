import { FormInstance } from 'antd';
import { client, extractData } from '@gowgates/api-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UrlId } from '@gowgates/utils';
import { Validation } from '../../types';
import { useFormBuilder } from '../useFormBuilder';
import { validatableQueryKey } from './useValidationsQuery';

const mutationFn = (id: UrlId) =>
  client()
    .delete(`/dynamic_data/validations/${id}`)
    .then(extractData<Validation>);

type MutationProps = {
  form?: FormInstance<Validation>;
  onSuccess?: () => void;
  onError?: () => void;
};

type Props = MutationProps & {
  validation?: Partial<Validation>;
};

export const useDeleteValidationMutation = ({ validation, onSuccess, onError }: Props) => {
  const queryClient = useQueryClient();
  const { fieldableId: validatableId, fieldableType: validatableType } = useFormBuilder();

  const invalidateQueries = () => {
    // will invalidate validatable data and request to fetch its validations
    queryClient.invalidateQueries({
      queryKey: validatableQueryKey({ validatableId, validatableType })
    });
  };

  const handleSuccess = () => {
    invalidateQueries();
    if (onSuccess) onSuccess();
  };

  const handleError = () => {
    invalidateQueries();
    if (onError) onError();
  };

  const mutation = useMutation({
    mutationFn: () => mutationFn(validation?.id),
    onSuccess: handleSuccess,
    onError: handleError
  });

  return {
    deleteValidation: mutation.mutate
  };
};
