import {
  Button,
  Tag,
  Space,
  Dropdown,
  Divider,
  Flex,
  Tooltip,
  Typography,
  InputNumber
} from 'antd';
import { RollbackOutlined } from '@ant-design/icons';
import { t } from '@gowgates/utils';
import { useFormBuilder } from '../../../hooks/useFormBuilder';
import { isAnyNumberField } from '../../../utils';
import { FormItem } from '../../forms';
import { ExpressionNode } from '../../../types';
import { operators } from './utils';

type Props = {
  expression: ExpressionNode[];
  setExpression: (expression: ExpressionNode[]) => void;
  result: string | null;
  setResult: (result: string | null) => void;
};

export const ExpressionBuilder = ({ expression, setExpression, result, setResult }: Props) => {
  const { fields } = useFormBuilder();

  const equalNode: ExpressionNode = { type: 'equal', name: '=', label: '=' };

  const addToExpression = (node: ExpressionNode) => {
    setExpression([...expression, node]);
  };

  const removeFromExpression = () => {
    const newArray = [...expression].slice(0, expression.length - 1);
    setResult('');
    setExpression(newArray);
  };

  const items = fields
    .filter((field) => isAnyNumberField(field))
    .map((field) => ({
      key: field.name,
      label: field.label,
      onClick: () => addToExpression({ type: 'field', name: field.name, label: field.label })
    }));

  const lastNode = () => expression[expression.length - 1];

  const disableOperators = () =>
    !lastNode() || lastNode()?.type === 'operator' || lastNode()?.type === 'equal';

  const disabledField = () => lastNode()?.type === 'field' || lastNode()?.type === 'equal';

  const isEqualDisabled = () =>
    !expression.find((node) => node.type === 'operator') || lastNode()?.type !== 'field';

  return (
    <FormItem name="expression" model="validation">
      <Flex justify="space-between">
        <Space size="large">
          <Dropdown menu={{ items }} trigger={['click']} disabled={disabledField()}>
            <Button>{t('dynamicData.field.select')}</Button>
          </Dropdown>
          <Space>
            {operators.map((operator) => (
              <Button onClick={() => addToExpression(operator)} disabled={disableOperators()}>
                {operator.label}
              </Button>
            ))}
          </Space>
          <Button onClick={() => addToExpression(equalNode)} disabled={isEqualDisabled()}>
            {equalNode.label}
          </Button>
        </Space>

        <Tooltip title={t('dynamicData.field.removeLast')}>
          <Button onClick={removeFromExpression} disabled={expression.length === 0}>
            <RollbackOutlined />
          </Button>
        </Tooltip>
      </Flex>

      <Divider />

      <div>
        {expression.length === 0 && (
          <Typography.Text type="secondary" italic>
            {t('dynamicData.validation.emptyMessage')}
          </Typography.Text>
        )}

        <Flex gap="8px 0" wrap>
          {expression.map((node) =>
            node.type === 'equal' ? (
              <>
                <Tag>{node.label}</Tag>
                <InputNumber
                  size="small"
                  controls={false}
                  style={{ width: 200 }}
                  value={result}
                  onChange={setResult}
                />
              </>
            ) : (
              <Tag color={node.type === 'field' ? 'blue' : ''}>{node.label}</Tag>
            )
          )}
        </Flex>
      </div>
    </FormItem>
  );
};
