import { MouseEventHandler } from 'react';
import { CloseCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import { Flex, Tooltip, Typography } from 'antd';
import { MutationFunction } from '@tanstack/react-query';
import { t } from '@gowgates/utils';
import { FileIcon } from './FileIcon';
import { ExtendedDangerPopconfirm } from './ExtendedDangerPopconfirm';

type FileProps = {
  url?: string;
  type?: string;
  name?: string;
  hasErrors?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  onRemove?: MouseEventHandler<HTMLInputElement>;
  onDestroy?: MutationFunction;
  iconRenderType?: 'file' | 'url';
  nameElementProps?: {
    className?: string;
  };
};

export const File = ({
  url,
  type = '',
  name = '',
  onRemove,
  onDestroy,
  hasErrors = false,
  errorMessage,
  disabled = false,
  iconRenderType = 'file',
  nameElementProps = {}
}: FileProps) => {
  const classNames = `file-field${hasErrors ? ' has-errors' : ''}`;

  return (
    <div className={classNames}>
      <div className="file-field-box">
        <Flex flex={1} className="minw-0">
          <FileIcon type={type} iconRenderType={iconRenderType} url={url} />
          {url ? (
            <a {...nameElementProps} href={url} target="_blank" rel="noopener noreferrer">
              {name}
            </a>
          ) : (
            <span {...nameElementProps}>{name}</span>
          )}
        </Flex>
        {onRemove && !disabled && (
          <Flex gap={4}>
            {hasErrors && errorMessage && (
              <Tooltip title={errorMessage}>
                <InfoCircleFilled style={{ color: 'var(--ant-color-error)' }} />
              </Tooltip>
            )}
            <Typography.Link onClick={onRemove} className="remove-file" disabled={disabled}>
              <CloseCircleFilled />
            </Typography.Link>
          </Flex>
        )}

        {onDestroy && !disabled && (
          <ExtendedDangerPopconfirm
            title={t('document.confirmDelete')}
            deleteFn={onDestroy}
            permission
            async={false}
          />
        )}
      </div>
    </div>
  );
};
