import PropTypes from 'prop-types';
import { DynamicFormItem } from '@gowgates/dynamic-fields';
import { Col, Form, Input, Row } from 'antd';
import { camelCase } from 'change-case';

const ClaimDetailsForm = ({ claimStructure }) => (
  <>
    <Row className="structure-validator-errors">
      <Col span={16} offset={8}>
        <Form.Item name={['data', 'base', 'data', camelCase(claimStructure.name)]}>
          <Input hidden />
        </Form.Item>
      </Col>
    </Row>

    {claimStructure.claim.map((field) => (
      <DynamicFormItem key={field.name} field={field} />
    ))}
  </>
);

ClaimDetailsForm.propTypes = {
  claimStructure: PropTypes.object.isRequired
};

export default ClaimDetailsForm;
