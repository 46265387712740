import React from 'react';
import { Link, useMatch } from 'react-router-dom';
import { Menu } from 'antd';
import { t } from '@gowgates/utils';

export const Nav = () => {
  const match = useMatch({ path: '/tenant/:tab', end: false });

  const menuItems = [
    {
      key: 'general',
      label: <Link to="general">{t('tenant.details')}</Link>
    },
    {
      key: 'dashboard-columns',
      label: (
        <Link to="dashboard-columns">{t('activerecord.attributes.tenant.dashboardColumns')}</Link>
      )
    },
    {
      key: 'tasks-columns',
      label: <Link to="tasks-columns">{t('activerecord.attributes.tenant.tasksColumns')}</Link>
    },
    {
      key: 'claims-columns',
      label: <Link to="claims-columns">{t('activerecord.attributes.tenant.claimsColumns')}</Link>
    }
  ];

  return (
    <Menu
      selectedKeys={match?.params?.tab ? [match?.params?.tab] : []}
      mode="horizontal"
      className="box-menu"
      items={menuItems}
    />
  );
};
