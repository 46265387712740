import { DynamicFormatter, Field } from '@gowgates/dynamic-fields';
import { AvatarAndName } from '@gowgates/core';

import { ClaimInTable, TenantColumnConfig } from '../../types';
import StructureName from '../../components/StructureName';
import ClaimStatusTag from '../claim/components/ClaimStatusTag';
import ClaimLiabilityTag from '../claim/components/ClaimLiabilityTag';

type Props = {
  record: ClaimInTable;
  columnConfig: TenantColumnConfig;
  field: Field;
};

export const ClaimTableColumn = ({ record, columnConfig, field }: Props) => {
  // It shouldn't be possible to have a task type in the claim column config,
  // but keeping here for typescript validation
  if (columnConfig.model === 'task') {
    return null;
  }

  const displayAs: Record<string, React.ReactNode> = {
    structureId: <StructureName claim={record} />,
    status: <ClaimStatusTag claim={record} />,
    assigneeId: <AvatarAndName user={record.assignee} />,
    liability: <ClaimLiabilityTag liability={record.liability} />
  };

  if (columnConfig.dataType === 'dynamic' && columnConfig.model !== 'claim') {
    return <DynamicFormatter data={record[columnConfig.model]?.data || {}} field={field} />;
  } else if (columnConfig.dataType === 'dynamic' && columnConfig.model === 'claim') {
    return <DynamicFormatter data={record.data} field={field} />;
  }

  if (columnConfig.dataType === 'fixed') {
    if (displayAs[columnConfig.value]) {
      return displayAs[columnConfig.value];
    }

    if (columnConfig.model === 'claim') {
      return <DynamicFormatter data={record} field={field} />;
    } else {
      return <DynamicFormatter data={record[columnConfig.model]} field={field} />;
    }
  }

  return null;
};
