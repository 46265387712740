/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { QueryClient } from '@tanstack/react-query';
import isPlainObject from 'lodash.isplainobject';
import Cookies from 'js-cookie';
import { camelCase } from 'change-case';
import { APIClientConfigsType } from '../utils/configs';
import { hasFiles, objectToFormData } from './objectToFormData';
import { convertObjectKeys } from './object';
import { railsSnake } from './utils';

declare module '@tanstack/react-query' {
  interface Register {
    defaultError: AxiosError;
  }
}

export const defaultOptionsQueryClient = {
  queries: {
    refetchOnWindowFocus: false,
    retry: 0,
    throwOnError: true
  }
};

export const defaultQueryClient = new QueryClient({
  defaultOptions: defaultOptionsQueryClient
});

const methods = ['get', 'post', 'put', 'patch', 'delete'];

const camelCaseWithDots = (str: string) =>
  str
    .split('.')
    .map((words) => camelCase(words))
    .join('.');

const convertData = (values: unknown) => {
  if (!values) {
    return values;
  }

  let newValues: any = {};

  if (isPlainObject(values)) {
    Object.entries(values).forEach(([key, value]) => {
      if (isPlainObject(value) || Array.isArray(value)) {
        newValues[`${key}_attributes`] = convertData(value);
      } else {
        newValues[key] = value;
      }
    });
  } else {
    newValues = values;
  }

  return newValues;
};

const transformData = (values: any) => {
  const newValues: any = {};

  Object.entries(values).forEach(([key, value]) => {
    newValues[key] = convertData(value);
  });

  return newValues;
};

class ApiClient {
  // Creates a method corresponding to each of the supported http verbs
  constructor(configs: APIClientConfigsType) {
    methods.forEach((method) => {
      (this as any)[method] = (path: string, requestConfigs: AxiosRequestConfig = {}) => {
        const defaultHeaders = {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: Cookies.get(configs.authTokenKey) || ''
        };

        const options: AxiosRequestConfig = {
          ...requestConfigs,
          params: requestConfigs.params
            ? convertObjectKeys(requestConfigs.params, railsSnake)
            : undefined
        };
        options.method = method;
        options.url = `${configs.baseURL}${path.startsWith('/') ? path : `/${path}`}`;
        options.headers = { ...options.headers, ...defaultHeaders };

        return axios(options);
      };

      return (this as any)[method];
    });
  }
}

export const initializeClientInstance = (configs: APIClientConfigsType) => {
  axios.defaults.transformRequest = (data) => {
    if (!data) {
      return '';
    }

    const transformedData = { ...transformData(data) };

    if (hasFiles(transformedData)) {
      return objectToFormData(transformedData);
    }

    return JSON.stringify(convertObjectKeys(transformedData, railsSnake));
  };

  axios.defaults.transformResponse = (data, headers) => {
    const extendedAuthorization = headers['x-extended-authorization'];
    if (extendedAuthorization) {
      Cookies.set(configs.authTokenKey, extendedAuthorization, {
        domain: configs.domain
      });
    }

    if (!data) {
      return {};
    }

    // The data might not be in json format. If not, it returns the original response.
    let jsonData;
    try {
      jsonData = JSON.parse(data);
    } catch (err) {
      return data;
    }

    const transformedData = convertObjectKeys(jsonData, camelCaseWithDots);

    return transformedData;
  };

  const clientInstance = new ApiClient(configs) as AxiosInstance;

  return clientInstance;
};
