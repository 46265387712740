import { useQuery } from '@tanstack/react-query';
import { t } from '@gowgates/utils';
import { useAuth, usePageTitle, useTable } from '@gowgates/core';
import { getTasks, getStatistics } from '../../api/endpoints';
import Page from '../../components/Page';
import { TaskInTable } from '../../types';
import { TasksTable } from '../tasks/Table';
import { TablePagination } from '../../components/TablePagination';
import Statistics from './Statistics';

export const Home = () => {
  usePageTitle('Dashboard');
  const { handleTableChange, search, sorter, filters, rowSelection } = useTable<TaskInTable>();
  const { currentUser } = useAuth();

  const { isFetching: isFetchingStats, data: statistics } = useQuery({
    queryKey: ['statistics'],
    queryFn: getStatistics
  });

  const { isFetching: isFetchingTasks, data: tasks } = useQuery({
    queryKey: ['myTasks', search],
    queryFn: () => getTasks({ user_id: currentUser?.id, ...search })
  });

  return (
    <Page title={t('statistics.mine')} overlap>
      <Statistics statistics={statistics} loading={isFetchingStats} />

      <h3>{t('statistics.openTasks')}</h3>

      <div className="page-table">
        <TasksTable
          tasks={tasks?.rows}
          loading={isFetchingTasks}
          sorter={sorter}
          filters={filters}
          rowSelection={rowSelection}
          onChange={handleTableChange}
          columnConfig="dashboardColumns"
        />

        <TablePagination pagination={tasks?.pagination} modelName="task" />
      </div>
    </Page>
  );
};
