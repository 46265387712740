import forIn from 'lodash.forin';
import omit from 'lodash.omit';
import { camelCase } from 'change-case';
import { Params, ValueLabelObject } from '../types';

export const updateSearch = ({ page: _page, ...baseParams }: Params, params: Params) => {
  const newParams = { ...baseParams };

  forIn(params, (value, key) => {
    newParams[key] = value || undefined;
  });

  return newParams;
};

export const updatedFiltersFromParams = (params: Params = {}) => {
  const paramsToConvert = omit(params, ['page', 'per_page', 'sort', 'q', 'requires_action']);
  const updatedFilters: Params = {};

  // Converts every filter to array if param is string
  forIn(paramsToConvert, (value, key) => {
    if (typeof value === 'string') {
      updatedFilters[camelCase(key)] = new Array(value);
    } else {
      updatedFilters[camelCase(key)] = value;
    }
  });

  return updatedFilters;
};

export const convertToFilter = (object: ValueLabelObject[]) =>
  object.map((entry) => ({ value: entry.value, text: entry.label }));

export default {};
