import { Space, Tooltip } from 'antd';
import { FrownOutlined } from '@ant-design/icons';
import { t } from '@gowgates/utils';
import { PriorityIcon, VulnerabilityIcon } from '../../icons';
import { isImportant } from '../claim/utils/claim';
import { Claim } from '../../types';

export const ClaimRowIcons = ({ record }: { record: Claim }) => (
  <Space>
    {record.complaint && (
      <Tooltip
        title={t('claim.complaintExplanation', {
          type: t(`claim.complaints.${record.complaint}`).toLowerCase()
        })}
      >
        <FrownOutlined />
      </Tooltip>
    )}

    {isImportant(record) && (
      <Tooltip title={t(`claim.isImportant.${record.importance}`)}>
        <PriorityIcon />
      </Tooltip>
    )}

    {record.vulnerableCustomer && (
      <Tooltip title={t('activerecord.attributes.claim.vulnerableCustomer')}>
        <VulnerabilityIcon />
      </Tooltip>
    )}
  </Space>
);
