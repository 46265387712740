import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row, Form, Popover, Input, Select, Space } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { t } from '@gowgates/utils';
import { RichTextHtml } from '@gowgates/core';
import { useAppConfigs } from '../../../hooks';
import { Field } from '../../../types';
import { FormItem } from '../../forms';

export const TextValidations = ({ field }: { field: Partial<Field> }) => {
  const form = Form.useFormInstance();
  const [isPatternActive, setPatternActive] = useState(true);
  const { appConfigs } = useAppConfigs();

  const patternTemplates = useMemo(
    () => [
      { value: 'no', label: t('dynamicData.field.validations.custom'), pattern: '' },
      {
        value: 'email',
        label: t('dynamicData.field.validations.email'),
        pattern: appConfigs.global.emailRegex
      },
      {
        value: 'url',
        label: t('dynamicData.field.validations.url'),
        pattern: appConfigs.global.urlRegex
      },
      {
        value: 'postcode',
        label: t('dynamicData.field.validations.auPostcode'),
        pattern: appConfigs.global.auPostcodeRegex
      }
    ],
    [appConfigs.global]
  );

  const updatePatternTemplate = useCallback(
    (value: string) => {
      const found = patternTemplates.find((pt) => pt.value === value);
      form.setFieldsValue({ pattern: found?.pattern });

      setPatternActive(value === 'no');
    },
    [form, patternTemplates]
  );

  useEffect(() => {
    updatePatternTemplate(field.patternTemplate || '');
  }, [field.patternTemplate, updatePatternTemplate]);

  return (
    <>
      <Form.Item label={t('activerecord.attributes.field.length')} className="no-margin">
        <Row gutter={10}>
          <Col span={12}>
            <FormItem name="minLength">
              <Input placeholder={t('activerecord.attributes.field.minLength')} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem name="maxLength">
              <Input placeholder={t('activerecord.attributes.field.maxLength')} />
            </FormItem>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item
        label={
          <Space>
            {t('activerecord.attributes.field.pattern')}
            <Popover
              content={
                <RichTextHtml
                  Component="span"
                  noStyle
                  htmlText={t('dynamicData.field.patternExplanation')}
                />
              }
            >
              <InfoCircleTwoTone />
            </Popover>
          </Space>
        }
        className="no-margin"
      >
        <Row gutter={10}>
          <Col span={6}>
            <FormItem name="patternTemplate">
              <Select options={patternTemplates} onChange={updatePatternTemplate} />
            </FormItem>
          </Col>
          <Col span={18}>
            <FormItem name="pattern">
              <Input disabled={!isPatternActive} />
            </FormItem>
          </Col>
        </Row>
      </Form.Item>
    </>
  );
};
