import { useQuery } from '@tanstack/react-query';
import { camelCase } from 'change-case';

import { apiConfigs, extractData } from '@gowgates/api-client';
import { UrlId } from '@gowgates/utils';

import { Validation } from '../../types';

type ValidatableProps = {
  validatableId: UrlId;
  validatableType: string;
};

type Props = ValidatableProps & {
  enabled?: boolean;
};

const client = () => apiConfigs().clientInstance;

const queryFn = ({ validatableId, validatableType }: ValidatableProps) =>
  client()
    .get('/dynamic_data/validations', {
      params: { validatable_id: validatableId, validatable_type: validatableType }
    })
    .then(extractData<Validation[]>);

export const validatableBaseQueryKey = ({ validatableId, validatableType }: ValidatableProps) => [
  camelCase(validatableType),
  validatableId
];

export const validatableQueryKey = (props: ValidatableProps) => [
  ...validatableBaseQueryKey(props),
  'validations'
];

export const useValidationsQuery = ({ validatableId, validatableType, enabled = true }: Props) => {
  const query = useQuery({
    queryKey: validatableQueryKey({ validatableId, validatableType }),
    queryFn: () => queryFn({ validatableId, validatableType }),
    staleTime: 120 * 1000, // refetch every 2 minutes
    enabled
  });

  return {
    validations: query.data,
    isLoading: query.isLoading
  };
};
