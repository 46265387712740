import { Dropdown, Button, App, Space, MenuProps } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

import { t } from '@gowgates/utils';
import { isUnprocessableEntity } from '@gowgates/api-client';

import { createClaim } from '../../api/endpoints';
import usePermissions from '../../hooks/usePermissions';
import HoverLoader from '../../components/HoverLoader';
import { Structure } from '../../types/structure';

type ClaimErrors = {
  message: string;
};

export const ClaimsActions = ({ structures }: { structures: Structure[] }) => {
  const { message } = App.useApp();
  const { permissions } = usePermissions();
  const navigate = useNavigate();

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: createClaim,
    onError: (error: AxiosError<ClaimErrors>) => {
      if (isUnprocessableEntity(error) && error?.response?.data?.message) {
        message.error(error.response.data.message);
      }
    },
    onSuccess: (data) => {
      navigate(`/claims/${data.id}`);
    }
  });

  const items = structures.map((structure) => ({
    key: `${structure.id}`,
    label: structure.name
  }));

  const onClickDummy: MenuProps['onClick'] = ({ key }) => {
    mutate({ claim: { structureId: key }, params: { dummy: true } });
  };

  const onClick: MenuProps['onClick'] = ({ key }) => mutate({ claim: { structureId: key } });

  return (
    <Space>
      {permissions.createDummyClaim && (
        <Dropdown
          menu={{ items, onClick: onClickDummy }}
          placement="bottomRight"
          trigger={['click']}
        >
          <Button type="primary">{t('claim.createDummy')}</Button>
        </Dropdown>
      )}

      {permissions.createClaim && (
        <Dropdown menu={{ items, onClick }} placement="bottomRight" trigger={['click']}>
          <Button type="primary">{t('claim.create')}</Button>
        </Dropdown>
      )}

      {isLoading && <HoverLoader />}
    </Space>
  );
};
