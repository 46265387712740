import { Form, Modal, Input, Select } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormItem, CgDatePicker } from '@gowgates/dynamic-fields';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { createTask } from '../../../../api/endpoints';
import { Claim, Item, Task } from '../../../../types';
import ModalFormFooter from '../../../../components/ModalFormFooter';
import usePermissions from '../../../../hooks/usePermissions';
import useResourceUsers from '../../../../hooks/useResourceUsers';
import useResourceTaskStructures from '../../../../hooks/useResourceTaskStructures';
import useClaim from '../../../../hooks/useClaim';

type NewTaskModalProps = {
  claim: Claim;
  isOpen?: boolean;
  setOpen: (open: boolean) => void;
  item?: Item;
};

const NewTaskModal = ({ claim, isOpen = false, setOpen, item }: NewTaskModalProps) => {
  const { permissions } = usePermissions();
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { allItems } = useClaim();
  const { users } = useResourceUsers();
  const { allTaskStructures, itemTaskStructures, isItemTaskStructure } = useResourceTaskStructures(
    claim.journeyId
  );

  const closeModal = () => {
    setOpen(false);
    form.resetFields();
  };

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values: Task) => createTask(claim.id, values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: () => {
      closeModal();
      queryClient.invalidateQueries({
        queryKey: ['claim', claim.id, 'tasks']
      });
      if (item) {
        queryClient.invalidateQueries({
          queryKey: ['claim', claim.id, 'items', item.id]
        });
      }
    }
  });

  const taskStructureIdUpdated = (prev: Task, current: Task) =>
    prev.taskStructureId !== current.taskStructureId;

  return (
    <Modal title={t('task.add')} open={isOpen} onCancel={closeModal} footer={null}>
      <Form
        form={form}
        layout="vertical"
        initialValues={{ itemId: item?.id }}
        onFinish={mutate}
        disabled={isLoading}
      >
        <FormItem name="taskStructureId" model="task" required>
          <Select
            options={item ? itemTaskStructures : allTaskStructures}
            fieldNames={{ value: 'id', label: 'name' }}
            showSearch
            optionFilterProp="name"
          />
        </FormItem>

        {item ? (
          <FormItem name="itemId" model="task" hidden>
            <Input />
          </FormItem>
        ) : (
          <Form.Item noStyle shouldUpdate={taskStructureIdUpdated}>
            {({ getFieldValue }) =>
              isItemTaskStructure(getFieldValue('taskStructureId')) && (
                <FormItem name="itemId" model="task" required>
                  <Select
                    options={allItems()}
                    fieldNames={{ value: 'id' }}
                    showSearch
                    optionFilterProp="name"
                  />
                </FormItem>
              )
            }
          </Form.Item>
        )}

        <FormItem name="description" model="task">
          <Input.TextArea />
        </FormItem>

        {permissions.assignTasksBulk && (
          <FormItem name="userId" model="task">
            <Select
              options={users}
              fieldNames={{ value: 'id', label: 'name' }}
              placeholder={t('task.userIdPlaceholder')}
              showSearch
              optionFilterProp="name"
            />
          </FormItem>
        )}

        <FormItem name="dueAt" model="task" required>
          <CgDatePicker allowClear={false} />
        </FormItem>

        <ModalFormFooter closeModal={closeModal} isLoading={isLoading} />
      </Form>
    </Modal>
  );
};

export default NewTaskModal;
