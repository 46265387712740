import { useQuery } from '@tanstack/react-query';
import { apiConfigs, extractData } from '@gowgates/api-client';
import { Field, ListItem } from '../types';

export const getResourceLists: () => Promise<ListItem[]> = () =>
  apiConfigs().clientInstance.get('resources/lists').then(extractData);

export const useLists = () => {
  const { isLoading, data: lists } = useQuery({
    queryKey: ['resourceLists'],
    queryFn: getResourceLists,
    placeholderData: [],
    staleTime: 60 * 60 * 1000 // stores cache for 1 hour
  });

  const choiceFromListValue = ({ field, fieldValue }: { field: Field; fieldValue: number[] }) => {
    const root = lists?.find((list) => list.id === field.listItemId);

    const fullPath = (
      currentPath: string[],
      remainingValues: number[],
      node: ListItem | undefined
    ): string[] => {
      const thisNode = node?.children.find((option) => option.id === remainingValues?.[0]);

      if (!thisNode) {
        return currentPath;
      }

      const newPath = [...currentPath, thisNode.name];

      if ((thisNode?.children?.length || 0) > 0) {
        return fullPath(newPath, remainingValues.slice(1, remainingValues.length), thisNode);
      }

      return newPath;
    };

    if (!root) {
      return '';
    }

    const out = fullPath([], fieldValue, root);

    return out.join(' / ');
  };

  return {
    isLoading,
    lists,
    choiceFromListValue
  };
};
