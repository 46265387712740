import { Table, TableProps } from 'antd';
import { Link } from 'react-router-dom';
import { useResourceRolesQuery, useTable } from '@gowgates/core';
import { Task } from '../../../types';
import useLegacyTableSearch from '../../../hooks/useLegacyTableSearch';
import useAppConfigs from '../../../hooks/useAppConfigs';
import { convertToFilter } from '../../../utils/tables';
import TaskStatus from '../../claim/show/Tasks/TaskStatus';
import useResourceUsers from '../../../hooks/useResourceUsers';

type AllTasksTableProps = {
  tasks?: Task[];
  loading: boolean;
  pagination: any;
  sorter: any;
  filters: any;
};

const AllTasksTable = ({
  tasks = [],
  loading,
  pagination,
  sorter,
  filters
}: AllTasksTableProps) => {
  const { appConfigs } = useAppConfigs();
  const { roleName, roleFilters } = useResourceRolesQuery();
  const { userFilters } = useResourceUsers();

  const useEllipsis = true;
  const { handleTableChange } = useTable<Task>();
  const { columnProps } = useLegacyTableSearch({ useEllipsis, columnWidth: 150 });

  const columns: TableProps<Task>['columns'] = [
    {
      ...columnProps('id', 'task'),
      render: (text, record) => (
        <Link to={`/claims/${record.claimId}/tasks?id=${text}`}>{text}</Link>
      )
    },
    { ...columnProps('claimId', 'task') },
    { ...columnProps('client', 'task'), title: appConfigs.fileConfig.clientDetailsLabel },
    { ...columnProps('claimant', 'task'), title: appConfigs.fileConfig.claimantDetailsLabel },
    { ...columnProps('name', 'task') },
    {
      ...columnProps('status', 'task', {
        sorter,
        filters,
        filterOptions: convertToFilter(appConfigs.task.statuses)
      }),
      render: (_text, record) => <TaskStatus task={record} />
    },
    { ...columnProps('createdAt', 'task', { sorter, filters, type: 'datetime' }) },
    { ...columnProps('dueAt', 'task', { sorter, filters, type: 'date' }) },
    { ...columnProps('description', 'task') },
    {
      ...columnProps('roleId', 'task', { sorter, filters, filterOptions: roleFilters }),
      render: (text) => roleName(text)
    },
    { ...columnProps('user', 'task', { sorter, filters, filterOptions: userFilters }) },
    { ...columnProps('completedAt', 'task', { sorter, filters, type: 'datetime' }) },
    { ...columnProps('completedBy', 'task', { sorter, filters, filterOptions: userFilters }) }
  ];

  return (
    <Table
      dataSource={tasks}
      rowKey={(record) => record.id}
      pagination={pagination}
      loading={loading}
      onChange={handleTableChange}
      scroll={{
        x: 1800
      }}
      columns={columns}
    />
  );
};

export default AllTasksTable;
