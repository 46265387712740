import { FormInstance } from 'antd';
import { AxiosError } from 'axios';
import { client, extractData } from '@gowgates/api-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UrlId, addErrorsFromAPIInForm } from '@gowgates/utils';
import { Field } from '../../types';
import { useFormBuilder } from '../useFormBuilder';
import { fieldableBaseQueryKey } from './useFieldsQuery';

const updateFn = (id: UrlId, field: Partial<Field>) =>
  client()
    .put(`/dynamic_data/fields/${id}`, { data: { field } })
    .then(extractData<Field>);

const createFn = (field: Partial<Field>) =>
  client()
    .post('/dynamic_data/fields', { data: { field } })
    .then(extractData<Field>);

type MutationProps = {
  form?: FormInstance<Field>;
  onSuccess?: () => void;
  onError?: () => void;
};

type UseSaveFieldMutationProps = MutationProps & {
  field: Partial<Field>;
};

export const useSaveFieldMutation = ({
  field,
  form,
  onSuccess,
  onError
}: UseSaveFieldMutationProps) => {
  const queryClient = useQueryClient();
  const { fieldableId, fieldableType, parentFieldableId, parentFieldableType } = useFormBuilder();

  const invalidateQueries = () => {
    // will invalidate fieldable data and request to fetch its fields
    queryClient.invalidateQueries({
      queryKey: fieldableBaseQueryKey({ fieldableId, fieldableType })
    });

    if (parentFieldableId && parentFieldableType) {
      // will invalidate parent fieldable data and request to fetch its fields
      queryClient.invalidateQueries({
        queryKey: fieldableBaseQueryKey({
          fieldableId: parentFieldableId,
          fieldableType: parentFieldableType
        })
      });
    }
  };

  const handleSuccess = () => {
    invalidateQueries();
    if (onSuccess) onSuccess();
  };

  const handleError = (error: AxiosError) => {
    invalidateQueries();
    if (form) addErrorsFromAPIInForm({ error, form });
    if (onError) onError();
  };

  const updateMutation = useMutation({
    mutationFn: (values: Partial<Field>) => updateFn(field.id, values),
    onSuccess: handleSuccess,
    onError: handleError
  });

  const createMutation = useMutation({
    mutationFn: (values: Partial<Field>) => createFn({ ...values, fieldableId, fieldableType }),
    onSuccess: handleSuccess,
    onError: handleError
  });

  const save = (values: Partial<Field>) => {
    if (field.id) {
      update(values);
    } else {
      create(values);
    }
  };

  const update = (values: Partial<Field>) => {
    updateMutation.mutate(values);
  };

  const create = (values: Partial<Field>) => {
    createMutation.mutate(values);
  };

  return {
    save,
    update,
    create
  };
};
