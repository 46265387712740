import { Fragment } from 'react';
import { Space, Typography } from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { camelCase } from 'change-case';
import mapKeys from 'lodash.mapkeys';

import { t } from '@gowgates/utils';

import { Field, FieldConditionQuery } from '../../types';
import { isArrayField } from '../../utils';
import { DynamicFormatter } from '../../formatters';

type FieldConditionQueryRules = FieldConditionQuery['rules'];
type FieldConditionQueryRuleItem = FieldConditionQueryRules[0];
type RuleItem = FieldConditionQueryRuleItem & { result?: boolean };
type Rules = RuleItem[];

type ConditionQueryProps = {
  rule: FieldConditionQuery | RuleItem;
  fields: Field[];
};

const ConditionQuery = ({ rule, fields }: ConditionQueryProps) => {
  if ('rules' in rule) {
    return (
      <ConditionRulesDescription rules={rule.rules} fields={fields} combinator={rule.combinator} />
    );
  }

  const field = fields?.find((field) => field.name === rule.field);

  if (!field) {
    return (
      <Typography.Text type="secondary" italic>
        Invalid rule
      </Typography.Text>
    );
  }

  const keyMap: { [key: string]: string } = { value: 'key', label: 'value' };
  const options = field?.options?.map((obj) => mapKeys(obj, (_, key) => keyMap[key]));
  const transformedField = { ...field, options };

  return (
    <Space>
      <div>
        <span>"{field.label}" </span>
        <span>{t(`dynamicData.field.conditionOperators.${rule.operator}`)} </span>
        <span>
          {isArrayField(field) ? (
            <ConditionQuery rule={rule.value as FieldConditionQuery} fields={field.children} />
          ) : (
            rule.operator !== 'unique' &&
            rule.operator !== 'duplicate' && (
              <>
                "
                <DynamicFormatter
                  data={{ [camelCase(rule.field || '')]: rule.value }}
                  field={transformedField}
                />
                "
              </>
            )
          )}
        </span>
      </div>
    </Space>
  );
};

type ConditionRulesDescriptionProps = {
  rules?: Rules;
  fields?: Field[];
  combinator?: FieldConditionQuery['combinator'];
};

export const ConditionRulesDescription = ({
  rules = [],
  combinator = 'and',
  fields = []
}: ConditionRulesDescriptionProps) => (
  <Typography.Paragraph>
    <blockquote>
      {rules.map((rule, index) => (
        <Fragment key={index}>
          <Space>
            <RuleResult result={rule.result} />
            <ConditionQuery rule={rule} fields={fields} />
          </Space>
          <div>
            {index !== rules.length - 1 && (
              <span className="combinator">{combinator.toUpperCase()}</span>
            )}
          </div>
        </Fragment>
      ))}
    </blockquote>
  </Typography.Paragraph>
);

const RuleResult = ({ result }: { result?: boolean }) => {
  if (result === true) {
    return (
      <Typography.Text type="success">
        <CheckCircleFilled className="text-danger" />
      </Typography.Text>
    );
  }

  if (result === false) {
    return (
      <Typography.Text type="danger">
        <CloseCircleFilled className="text-danger" />
      </Typography.Text>
    );
  }

  return null;
};
