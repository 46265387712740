import { useState } from 'react';
import { Card, Flex, Tag, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { ExpressionNode, Validation } from '../../../types';
import { useFormBuilder } from '../../../hooks/useFormBuilder';
import { EQUAL_SIGN, operators } from './utils';
import { ValidationsForm } from './Form';

type Props = {
  validation: Validation;
};

const OperatorNode = ({ node }: { node: ExpressionNode }) => {
  const operator = operators?.find((operator) => operator.name === node.name);

  return <Tag>{operator?.label || node.name}</Tag>;
};

const FieldNode = ({ node }: { node: ExpressionNode }) => {
  const { fields } = useFormBuilder();

  const field = fields?.find((field) => field.name === node.name);

  return <Tag color="blue">{field?.label || node.name}</Tag>;
};

const Node = ({ node }: { node: ExpressionNode }) => {
  if (node.type === 'field') {
    return <FieldNode node={node} />;
  }

  if (node.type === 'operator') {
    return <OperatorNode node={node} />;
  }

  if (node.type === 'equal') {
    return <Tag>{EQUAL_SIGN}</Tag>;
  }

  if (node.type === 'result') {
    return <Tag color="orange">{node.value}</Tag>;
  }

  return null;
};

export const ValidationDetails = ({ validation }: Props) => {
  const [isEditing, setEditing] = useState(false);

  if (isEditing) {
    return <ValidationsForm setAdding={setEditing} validation={validation} />;
  }

  return (
    <Card
      title={
        <Flex justify="space-between">
          <Typography.Text>{validation.name}</Typography.Text>
          <Typography.Link onClick={() => setEditing(true)}>
            <EditOutlined />
          </Typography.Link>
        </Flex>
      }
    >
      {validation.expression.map((node) => (
        <Node key={`${node.type}-${node.name}`} node={node} />
      ))}
    </Card>
  );
};
