import { FormInstance } from 'antd';
import { AxiosError } from 'axios';
import { client, extractData } from '@gowgates/api-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UrlId, addErrorsFromAPIInForm } from '@gowgates/utils';
import { Validation } from '../../types';
import { useFormBuilder } from '../useFormBuilder';
import { validatableQueryKey } from './useValidationsQuery';

const updateFn = (id: UrlId, validation: Partial<Validation>) =>
  client()
    .put(`/dynamic_data/validations/${id}`, { data: { validation } })
    .then(extractData<Validation>);

const createFn = (validation: Partial<Validation>) =>
  client()
    .post('/dynamic_data/validations', { data: { validation } })
    .then(extractData<Validation>);

type MutationProps = {
  form?: FormInstance<Validation>;
  onSuccess?: () => void;
  onError?: () => void;
};

type UseSavevalidationMutationProps = MutationProps & {
  validation?: Partial<Validation>;
};

export const useSaveValidationMutation = ({
  validation,
  form,
  onSuccess,
  onError
}: UseSavevalidationMutationProps) => {
  const queryClient = useQueryClient();
  const { fieldableId: validatableId, fieldableType: validatableType } = useFormBuilder();

  const invalidateQueries = () => {
    // will invalidate validatable data and request to fetch its validations
    queryClient.invalidateQueries({
      queryKey: validatableQueryKey({ validatableId, validatableType })
    });
  };

  const handleSuccess = () => {
    invalidateQueries();
    if (onSuccess) onSuccess();
  };

  const handleError = (error: AxiosError) => {
    invalidateQueries();
    if (form) addErrorsFromAPIInForm({ error, form });
    if (onError) onError();
  };

  const updateMutation = useMutation({
    mutationFn: (values: Partial<Validation>) => updateFn(validation?.id, values),
    onSuccess: handleSuccess,
    onError: handleError
  });

  const createMutation = useMutation({
    mutationFn: (values: Partial<Validation>) =>
      createFn({ ...values, validatableId, validatableType }),
    onSuccess: handleSuccess,
    onError: handleError
  });

  const save = (values: Partial<Validation>) => {
    if (validation?.id) {
      update(values);
    } else {
      create(values);
    }
  };

  const update = (values: Partial<Validation>) => {
    updateMutation.mutate(values);
  };

  const create = (values: Partial<Validation>) => {
    createMutation.mutate(values);
  };

  return {
    save,
    update,
    create
  };
};
