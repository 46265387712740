import { ExpressionNode } from '../../../types';

export const operators: ExpressionNode[] = [
  { type: 'operator', name: 'plus', label: '+' },
  { type: 'operator', name: 'minus', label: '-' },
  { type: 'operator', name: 'times', label: 'x' },
  { type: 'operator', name: 'divide', label: '/' }
];

export const EQUAL_SIGN = '=';
