import { Table, TableProps } from 'antd';
import { Link } from 'react-router-dom';
import { camelCase } from 'change-case';

import { Filters, Sorts, ReturnUseTable } from '@gowgates/core';

import useResourceUsers from '../../hooks/useResourceUsers';
import { claimRowClassName, convertToFilter } from '../../utils/tables';
import { ClaimRowIcons } from '../claims/ClaimRowIcons';
import useAppConfigs from '../../hooks/useAppConfigs';
import { AvailableFilters, TaskInTable } from '../../types';
import useCustomColumns from '../../hooks/useCustomColumns';
import { TaskTableColumn } from './TaskTableColumn';
import { TaskCol } from './TaskCol';

type Props = {
  tasks?: TaskInTable[];
  loading: boolean;
  filters: Filters<TaskInTable>;
  sorter: Sorts<TaskInTable>;
  rowSelection: ReturnUseTable<TaskInTable>['rowSelection'];
  onChange: ReturnUseTable<TaskInTable>['handleTableChange'];
  columnConfig: 'dashboardColumns' | 'tasksColumns';
};

export const TasksTable = ({
  tasks = [],
  loading,
  filters,
  sorter,
  rowSelection,
  onChange,
  columnConfig
}: Props) => {
  const { findField, columnConfigs } = useCustomColumns({ table: columnConfig });
  const { userFilters } = useResourceUsers();
  const { appConfigs } = useAppConfigs();

  const availableFilters: AvailableFilters = {
    'task.user_id': userFilters,
    'task.status': convertToFilter(appConfigs.task.openStatuses)
  };

  const columns: TableProps<TaskInTable>['columns'] = columnConfigs.map((columnConfig) => {
    const field = findField(columnConfig);

    if (!field) return {};

    const rawColumnName = `${columnConfig.model}.${field.name}`;
    const columnName = camelCase(`${columnConfig.model}.${field.name}`);

    const isSortable = columnConfig.dataType === 'fixed' || !!field.template;

    return {
      title: field.label,
      dataIndex: columnName,
      sorter: isSortable,
      sortOrder: sorter.field === columnName ? sorter.order : null,
      filters: availableFilters[rawColumnName],
      filteredValue: filters[columnName] || null,
      render: (_text: unknown, record: TaskInTable) => (
        <TaskCol task={record}>
          <TaskTableColumn record={record} columnConfig={columnConfig} field={field} />
        </TaskCol>
      )
    };
  });

  columns?.push({
    className: 'text-right claim-info-icons',
    render: (_text, record) => (
      <Link to={`/claims/${record.id}`} className="col-clickable">
        <ClaimRowIcons record={record.claim} />
      </Link>
    )
  });

  return (
    <Table
      dataSource={tasks}
      rowKey={(record) => record.id}
      pagination={false}
      loading={loading}
      onChange={onChange}
      className="page-table"
      rowSelection={rowSelection}
      rowClassName={(record) => claimRowClassName(record.claim)}
      columns={columns}
    />
  );
};
