import { Select, Form } from 'antd';

type AntSelectProps = React.ComponentProps<typeof Select>;
type AntFormItemProps = React.ComponentProps<typeof Form.Item>;

type ClearableSelectProps = Omit<AntSelectProps, 'allowClear'> &
  Required<Pick<AntFormItemProps, 'name'>> & {
    clearValue?: undefined | null | unknown;
  };

export const ClearableSelect = ({
  name,
  onClear,
  clearValue = null,
  ...selectProps
}: ClearableSelectProps) => {
  const form = Form.useFormInstance();

  return (
    <Select
      allowClear
      onClear={() => {
        if (onClear) onClear();

        // the timeout is needed to make sure that ant do not override
        // the clearValue we desire (e.g. null), with undefined,
        // after onClear is executed, when allowClear is true
        setTimeout(() => form.setFieldValue(name, clearValue), 1);
      }}
      {...selectProps}
    />
  );
};
