import { Button, Card, Space, Divider, Flex, Form, Input, Popconfirm } from 'antd';
import { useState } from 'react';
import { t } from '@gowgates/utils';
import { useFormBuilder } from '../../../hooks/useFormBuilder';
import { useSaveValidationMutation } from '../../../hooks/validations/useSaveValidationMutation';
import { FormItem } from '../../forms';
import { ExpressionNode, Field, Validation } from '../../../types';
import { useDeleteValidationMutation } from '../../../hooks/validations/useDeleteValidationMutation';
import { EQUAL_SIGN, operators } from './utils';
import { ExpressionBuilder } from './ExpressionBuilder';

type Props = {
  setAdding: (adding: boolean) => void;
  validation?: Validation;
};

const operatorNode = ({ node }: { node: ExpressionNode }) => {
  const operator = operators?.find((operator) => operator.name === node.name);

  return {
    type: node.type,
    name: node.name,
    label: operator?.label || node.name
  };
};

const fieldNode = ({ node, fields }: { node: ExpressionNode; fields: Field[] }) => {
  const field = fields?.find((field) => field.name === node.name);

  return {
    type: node.type,
    name: node.name,
    label: field?.label || node.name
  };
};

const initializeExpression = ({
  validation,
  fields
}: {
  validation?: Validation;
  fields: Field[];
}) => {
  if (!validation || !validation.expression) {
    return [];
  }

  return validation.expression
    .map((node) => {
      if (node.type === 'field') {
        return fieldNode({ node, fields });
      }

      if (node.type === 'operator') {
        return operatorNode({ node });
      }

      if (node.type === 'equal') {
        return {
          type: node.type,
          name: node.name,
          label: EQUAL_SIGN
        };
      }

      return node;
    })
    .filter((node) => node.type === 'field' || node.type === 'operator' || node.type === 'equal');
};

const initializeResult = (validation?: Validation) =>
  validation?.expression?.find((node) => node.type === 'result')?.value || '';

export const ValidationsForm = ({ setAdding, validation }: Props) => {
  const { fields } = useFormBuilder();
  const [form] = Form.useForm();
  const [expression, setExpression] = useState<ExpressionNode[]>(
    initializeExpression({ validation, fields })
  );
  const [result, setResult] = useState<string | null>(initializeResult(validation));
  const { save } = useSaveValidationMutation({
    validation,
    form,
    onSuccess: () => {
      setAdding(false);
    }
  });
  const { deleteValidation } = useDeleteValidationMutation({
    validation,
    onSuccess: () => {
      setAdding(false);
    }
  });

  const saveExpression = (values: Validation) => {
    save({
      ...values,
      expression: [...expression, { type: 'result', value: result }]
    });
  };

  return (
    <Card title={t('dynamicData.validation.new')}>
      <Form form={form} initialValues={validation} layout="vertical" onFinish={saveExpression}>
        <FormItem name="name" model="validation">
          <Input data-1p-ignore data-lpignore="true" />
        </FormItem>

        <FormItem name="errorMessage" model="validation">
          <Input />
        </FormItem>

        <ExpressionBuilder
          expression={expression}
          setExpression={setExpression}
          result={result}
          setResult={setResult}
        />

        <Divider />

        <Flex justify="space-between">
          <span>
            {validation?.id && (
              <Popconfirm
                title={t('dynamicData.validation.delete')}
                description={t('dynamicData.validation.areYouSure')}
                onConfirm={() => deleteValidation()}
                okText={t('globals.yesNo.true')}
                okType="danger"
                icon={null}
                cancelText={t('globals.yesNo.false')}
              >
                <Button type="primary" danger>
                  {t('globals.delete')}
                </Button>
              </Popconfirm>
            )}
          </span>

          <Space>
            <Button onClick={() => setAdding(false)}>{t('globals.cancel')}</Button>
            <Button type="primary" htmlType="submit">
              {t('globals.save')}
            </Button>
          </Space>
        </Flex>
      </Form>
    </Card>
  );
};
