import { Table, TableProps } from 'antd';
import { camelCase } from 'change-case';

import { Filters, ReturnUseTable, Sorts } from '@gowgates/core';
import { convertToFilter } from '@gowgates/utils';
import { useAppConfigs } from '@gowgates/dynamic-fields';

import { claimRowClassName } from '../../utils/tables';
import { AvailableFilters, ClaimInTable } from '../../types';
import { Structure } from '../../types/structure';
import useCustomColumns from '../../hooks/useCustomColumns';
import useCaseManagers from '../../hooks/useCaseManagers';
import { ClaimRowIcons } from './ClaimRowIcons';
import { ClaimCol } from './ClaimCol';
import { ClaimTableColumn } from './ClaimTableColumn';

type Props = {
  claims?: ClaimInTable[];
  loading: boolean;
  filters: Filters<ClaimInTable>;
  sorter: Sorts<ClaimInTable>;
  rowSelection: ReturnUseTable<ClaimInTable>['rowSelection'];
  structures: Structure[];
  onChange: ReturnUseTable<ClaimInTable>['handleTableChange'];
};

export const ClaimsTable = ({
  claims = [],
  loading,
  sorter,
  filters,
  structures,
  rowSelection,
  onChange
}: Props) => {
  const { findField, columnConfigs } = useCustomColumns({ table: 'claimsColumns' });
  const { appConfigs } = useAppConfigs();

  const { userFilters } = useCaseManagers();
  const structureFilters = structures.map((structure) => ({
    text: structure.name,
    value: structure.claimStructureId
  }));

  const availableFilters: AvailableFilters = {
    'claim.status': convertToFilter(appConfigs.claim.statuses),
    'claim.assignee_id': userFilters,
    'claim.structure_id': structureFilters
  };

  const columns: TableProps<ClaimInTable>['columns'] = columnConfigs.map((columnConfig) => {
    const field = findField(columnConfig);

    if (!field) return {};

    const rawColumnName = `${columnConfig.model}.${field.name}`;
    const columnName = camelCase(`${columnConfig.model}.${field.name}`);

    const isSortable = columnConfig.dataType === 'fixed' || !!field.template;

    return {
      title: field.label,
      dataIndex: columnName,
      sorter: isSortable,
      sortOrder: sorter.field === columnName ? sorter.order : null,
      filters: availableFilters[rawColumnName],
      filteredValue: filters[columnName] || null,
      render: (_text, record) => (
        <ClaimCol claim={record}>
          <ClaimTableColumn record={record} columnConfig={columnConfig} field={field} />
        </ClaimCol>
      )
    };
  });

  columns?.push({
    className: 'text-right claim-info-icons',
    render: (_text, record) => (
      <ClaimCol claim={record}>
        <ClaimRowIcons record={record} />
      </ClaimCol>
    )
  });

  return (
    <Table
      dataSource={claims}
      rowKey={(record) => record.id}
      pagination={false}
      loading={loading}
      onChange={onChange}
      rowSelection={rowSelection}
      rowClassName={claimRowClassName}
      columns={columns}
    />
  );
};
