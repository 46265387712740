import { PropsWithChildren } from 'react';
import { Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { t } from '@gowgates/utils';
import { ClaimLiability } from '../../../types';

const statuses: { [key in ClaimLiability]: string } = {
  undefined: '',
  approved: 'green',
  declined: 'error',
  withdrawn: 'warning'
};

const icons: { [key in ClaimLiability]?: JSX.Element | null } = {
  approved: <CheckCircleOutlined />,
  declined: <CloseCircleOutlined />
};

type ClaimLiabilityTagProps = {
  liability: ClaimLiability;
  TooltipComponent?: React.FC<PropsWithChildren<{ title?: string }>>;
};

const ClaimLiabilityTag = ({
  liability,
  TooltipComponent = ({ children }) => children
}: ClaimLiabilityTagProps) => (
  <Tag icon={icons[liability]} color={statuses[liability]}>
    <TooltipComponent title={t(`claim.liabilities.${liability}`)}>
      {t(`claim.liabilities.${liability}`)}
    </TooltipComponent>
  </Tag>
);

export default ClaimLiabilityTag;
