import { Cascader, Form } from 'antd';

import { Field } from '../../types';
import { useLists } from '../../hooks';
import { FormItem, FormItemProps } from './FormItem';

type ChoiceFromListFieldProps = {
  name: FormItemProps['name'];
  field: Field;
  disabled?: boolean;
};

export const ChoiceFromListField = ({
  name,
  field,
  disabled = false
}: ChoiceFromListFieldProps) => {
  const { label, required, description } = field;
  const { lists } = useLists();
  const form = Form.useFormInstance();

  const options = lists?.find((list) => list.id === field.listItemId)?.children;

  return (
    <FormItem name={name} label={label} required={required} extra={description}>
      <Cascader
        options={options}
        showSearch
        fieldNames={{ label: 'name', value: 'id' }}
        disabled={disabled}
        allowClear={!required}
        // the timeout is needed to make sure that ant do not override null value with undefined,
        // after onClear is executed, when allowClear is true
        onClear={() => setTimeout(() => form.setFieldValue(name, []), 1)}
      />
    </FormItem>
  );
};
