import { t } from '@gowgates/utils';
import { Field, ConditionOperatorIDType } from '../types';

type FieldType = Field['type'];

const numberTypes: FieldType[] = ['integer', 'number', 'money'];
const textTypes: FieldType[] = ['string', 'text'];
const typesWithValidation: FieldType[] = [...numberTypes, ...textTypes];

export const hasValidation = (type: FieldType) => typesWithValidation.indexOf(type) >= 0;
export const isNumber = (type: FieldType) => numberTypes.indexOf(type) >= 0;
export const isText = (type: FieldType) => textTypes.indexOf(type) >= 0;

type FieldTypeChecker = (
  f?: Field | Pick<Field, 'type' | 'multiple' | 'optionsType' | 'displayType' | 'patternTemplate'>
) => boolean;
export const isStringField: FieldTypeChecker = (field) => field?.type === 'string';
export const isTextField: FieldTypeChecker = (field) => field?.type === 'text';
export const isIntegerField: FieldTypeChecker = (field) => field?.type === 'integer';
export const isNumberField: FieldTypeChecker = (field) => field?.type === 'number';
export const isMoneyField: FieldTypeChecker = (field) => field?.type === 'money';
export const isPercentField: FieldTypeChecker = (field) => field?.type === 'percent';
export const isChoiceField: FieldTypeChecker = (field) => field?.type === 'choice';
export const isSingleChoiceField: FieldTypeChecker = (field) =>
  isChoiceField(field) && !field?.multiple;
export const isMultiChoiceField: FieldTypeChecker = (field) =>
  isChoiceField(field) && Boolean(field?.multiple);
export const isBooleanField: FieldTypeChecker = (field) => field?.type === 'boolean';
export const isDateField: FieldTypeChecker = (field) => field?.type === 'date';
export const isAddressField: FieldTypeChecker = (field) => field?.type === 'address';
export const isPhoneField: FieldTypeChecker = (field) => field?.type === 'phone';
export const isArrayField: FieldTypeChecker = (field) => field?.type === 'array';
export const isChoiceFieldWithList: FieldTypeChecker = (field) =>
  field?.type === 'choice' && field?.optionsType === 'list';
export const isEmailField: FieldTypeChecker = (field) =>
  isStringField(field) && field?.patternTemplate === 'email';
export const isUrlField: FieldTypeChecker = (field) =>
  isStringField(field) && field?.patternTemplate === 'url';
export const isFileField: FieldTypeChecker = (field) => field?.type === 'file';

export const isDisplayingAsSelect: FieldTypeChecker = (field) =>
  field?.displayType === 'select_box';

export const isAnyNumberField: FieldTypeChecker = (field) =>
  isIntegerField(field) || isNumberField(field) || isMoneyField(field) || isPercentField(field);

export const isAnyTextField: FieldTypeChecker = (field) =>
  isStringField(field) || isTextField(field);

export const conditionOperatorsForField = (field: Field) => {
  const out = new Set<ConditionOperatorIDType>([]);

  if (!isMultiChoiceField(field) && !isArrayField(field)) {
    out.add('eq');
  }

  if (!isMultiChoiceField(field) && !isArrayField(field)) {
    out.add('neq');
  }

  if (isAnyNumberField(field) || isDateField(field)) {
    out.add('lt');
    out.add('gt');
    out.add('lte');
    out.add('gte');
  }

  if (isMultiChoiceField(field)) {
    out.add('inc');
    out.add('exc');
  }

  if (isArrayField(field)) {
    out.add('include_one');
    out.add('include_all');
    out.add('include_none');
  }

  if (isStringField(field)) {
    out.add('unique');
    out.add('duplicate');
  }

  return Array.from(out).map((operator) => ({
    value: operator,
    label: t(`dynamicData.field.conditionOperators.${operator}`)
  }));
};

export const findField = (fields: Field[], name: string) => fields.find((f) => f.name === name);

export const fieldsForReactQueryBuilder = (fields: Field[] = []) =>
  fields.map((f) => {
    const fieldOperators = conditionOperatorsForField(f).map(({ label, value }) => ({
      name: value,
      label
    }));

    return {
      name: f.name,
      label: f.label,
      operator: fieldOperators[0]?.name,
      operators: fieldOperators,
      rawField: f
    };
  });
