import { Input, Select } from 'antd';

import { t } from '@gowgates/utils';

import { isDateField, isChoiceField, isMoneyField } from '../../utils';
import { Field } from '../../types';
import { FormItem } from './FormItem';
import { CgDatePicker } from './CgDatePicker';
import { MoneyFormItem } from './MoneyFormItem';

type DynamicHardcodedFieldProps = {
  name: (string | number) | (string | number)[];
  fieldName: string;
  modelName: string;
  modelFields: Field[];
};

type FormControlProps = {
  fieldStructure: Field;
};

const FormControl = ({ fieldStructure, ...props }: FormControlProps) => {
  if (isChoiceField(fieldStructure)) return <Select options={fieldStructure.options} {...props} />;
  // allowClear is false because <FormItem> wrapper make this control to a required one
  if (isDateField(fieldStructure)) return <CgDatePicker allowClear={false} {...props} />;

  return <Input {...props} />;
};

export const DynamicHardcodedField = ({
  name,
  fieldName,
  modelName,
  modelFields
}: DynamicHardcodedFieldProps) => {
  const fieldStructure = modelFields?.find((field) => field.name === fieldName);
  if (!fieldStructure) return;

  const formItemProps = {
    name,
    model: modelName,
    required: true,
    rules: [{ required: true, message: t('errors.messages.blank') }]
  };
  if (isMoneyField(fieldStructure)) return <MoneyFormItem {...formItemProps} />;

  return (
    <FormItem {...formItemProps}>
      <FormControl fieldStructure={fieldStructure} />
    </FormItem>
  );
};
