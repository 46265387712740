import { Button, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { t } from '@gowgates/utils';

import { useTenant } from '../../../hooks/useTenant';
import { ColumnConfigTypes, Tenant } from '../../../types';
import { updateTenant } from '../../../api/endpoints';
import { ColumnConfig } from './ColumnConfig';

export const ColumnsConfig = ({ table }: { table: ColumnConfigTypes }) => {
  const [form] = Form.useForm<Tenant>();
  const { tenant } = useTenant();

  const onFinish = (values: Tenant) => {
    updateTenant(values);
  };

  return (
    <Form form={form} initialValues={tenant} layout="vertical" onFinish={onFinish}>
      <Form.List name={table}>
        {(columns, { add, remove }) => (
          <>
            {columns.map((column) => (
              <ColumnConfig key={column.key} column={column} remove={remove} table={table} />
            ))}
            <Form.Item>
              <Button onClick={() => add()} icon={<PlusOutlined />}>
                {t('tenant.columns.add')}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {t('globals.save')}
        </Button>
      </Form.Item>
    </Form>
  );
};
